import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import TechTags from "../../components/techTags";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1><a parentName="h1" {...{
        "href": "http://inmovending.com"
      }}>{`Inmovending`}</a></h1>
    <p>{`I started my career by creating WordPress websites while I was still studying.
Although I didn't know much about software design, DDD, or clean code,
this experience taught me a lot about how web development works. `}</p>
    <p>{`As I progressed, I realized the importance of a development environment, version control and structure developer cycles.
I was the only develper so I needed to figure out on my own, I used to work directly on the production server, one time I
made a mistake I could recover from, so I started the project from scratch.`}</p>
    <p>{`This was the first time that I needed to setup DNS servers, Apache, and web hosting. I really enjoy being able to ship
something that everybody could access.`}</p>
    <p>{`I used to delete this part of my journey from my resume, I thought wordpress and all the mistakes I made were to much to
share, now I realize that this is part of my journey and I'm proud of it. Also for a long time I thought WordPress was
the root of all evil, now I realize that different tools have their place depending on the problem you're trying to solve.`}</p>
    <p>{`While WordPress may not be the best tool for every situation, it has its strengths,
especially in e-commerce.`}</p>
    <p>{`The right tool for the job is the one that helps you achieve your goals efficiently and effectively.
The key is knowing when to use the drill and when to use the hammer.`}</p>
    <TechTags title="Jobs" tags={["WordPress Admin"]} mdxType="TechTags" />
    <h2>{`Challenges`}</h2>
    <p>{`The journey had a lot of challenges and these are some of them. They aren't in order of difficulty`}</p>
    <ul>
      <li parentName="ul">{`Learning to change DNS`}</li>
      <li parentName="ul">{`Working with WordPress plugins`}</li>
      <li parentName="ul">{`Trying to transform an excel catalog into Mysql and implement it on a nonflexible template`}</li>
    </ul>
    <TechTags title="Technologies" tags={["Mysql", "Hostinger", "WORDPRESS"]} mdxType="TechTags" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      